@layer components {
  .Jumbotron {
    @apply bg-cover bg-center;

    background-image: url("../../assets/images/bg1-min.jpg");

    .wrapper__background {
      @apply h-full bg-gray-900 bg-opacity-50 text-white text-opacity-75 py-12 sm:py-32 md:py-48 text-3xl px-4 box-border;

      font-family: 'Roboto Slab', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

      //region Wrapper content
      .wrapper__content {
        @apply text-center md:text-left mx-auto sm:container md:container lg:container xl:container max-w-xs sm:max-w-sm md:max-w-lg lg:max-w-2xl xl:max-w-4xl;

        h2 {
          @apply text-3xl sm:text-4xl md:text-6xl xl:text-7xl text-white font-bold pb-16 md:pb-8;
        }

        p {
          @apply text-lg sm:text-3xl pb-8;

          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        }

        //region
        ul {
          @apply box-border list-none list-inside text-center md:text-left pb-16;

          li {
            @apply text-sm sm:text-lg md:text-xl;

            &:before {
              @apply mr-2;
              content: '-';
            }
          }
        }

        //endregion

        .do__request {
          @apply animate-pulse bg-yellow-600 text-sm rounded-xl p-4 hover:bg-yellow-500 transition uppercase max-w-xs;

          &:hover {
            @apply animate-none;
          }
        }
      }

      //endregion
    }
  }
}
