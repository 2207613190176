@import "src/components/nav/Nav";
@import "src/components/jumbotron/Jumbotron";
@import "src/components/info/Info";
@import "src/components/slider/Slider";
@import "src/components/footer/Footer";
@import "src/components/notFound/NotFound";

@layer components {

  //region Application
  .App {
    text-align: center;

    @apply h-screen;

    * {
      @apply w-full;
    }
  }

  //endregion
}
