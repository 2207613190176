@layer components {
  .Info {
    @apply bg-blue-500 text-white;

    h2 {
      @apply relative font-bold text-xl md:text-2xl lg:text-4xl p-4 lg:container lg:max-w-4xl pt-8 mb-3;

      &.info__header__extend {
        @apply lg:max-w-6xl;
      }

      span {
        @apply sm:whitespace-pre;
      }

      &:after {
        @apply border-b-4 text-blue-500 border-yellow-500 border-opacity-100 bottom-1;

        position: absolute;
        left: calc(50% - 20px);
        content: '_';
        width: 40px;
      }
    }

    .info__wrapper {
      @apply bg-white;

      .info__wrapper__grid {
        @apply grid grid-cols-1 sm:grid-cols-2 gap-4 px-4 py-8 sm:container lg:max-w-4xl;

        .info__card:not(:last-child), .info__card__extend:not(:last-child) {
          @apply border-b-2 sm:border-none;
        }

        .info__card {
          @apply grid grid-cols-6 justify-center content-center py-6;

          svg {
            @apply row-span-2 text-blue-500 w-8 h-8 lg:w-12 lg:h-12 mx-auto flex self-center;
          }

          h5, h5.only__title {
            @apply col-span-5 text-gray-800 text-xs md:text-sm lg:text-lg h-full pl-4 text-left font-bold;
          }

          h5.only__title {
            @apply row-span-2 flex items-center;
          }

          p {
            @apply col-span-5 text-gray-500 text-xs md:text-sm lg:text-lg h-full pl-4 text-left;
          }
        }

        .info__card__extend {
          @apply py-6;

          h4 {
            @apply relative text-gray-800 text-sm sm:text-base md:text-lg lg:text-2xl text-center sm:text-left pb-2 font-normal md:font-bold;

            span {
              @apply sm:whitespace-pre;
            }

            &:after {
              position: absolute;
              content: '_';
              left: calc(50% - 2rem);
              width: 4rem;

              @apply border-b-4 text-white text-opacity-0 border-blue-500 border-opacity-100 -bottom-2 sm:left-0;
            }
          }
        }
      }
    }
  }
}
