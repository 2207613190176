@layer components {
  .not-found {
    @apply h-screen w-full bg-blue-900 flex flex-col justify-center items-center content-center;

    h1 {
      @apply text-white text-7xl sm:text-9xl font-black;

      font-family: 'Roboto Slab', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }

    h2 {
      @apply text-white text-2xl sm:text-3xl text-center;

      font-family: 'Roboto Slab', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }
  }
}
