@layer components {
  .Slider {

    @apply bg-purple-300 overflow-hidden grid grid-cols-1 sm:grid-cols-3;

    .slider__lightbox__wrapper {
      @apply fixed top-0 h-full bg-gray-800 bg-opacity-75 z-50;

      img.slider__lightbox__image {
        @apply duration-300 transform h-full p-0 sm:p-16 cursor-pointer object-contain;
      }
    }

    div {
      @apply overflow-hidden;
      &:hover {

        img {
          @apply duration-300 transform scale-110 cursor-pointer;
        }
      }
    }
  }
}
