@layer components {
  footer {
    @apply bg-cover bg-center;
    background-image: url("../../assets/images/bg2-min.jpg");

    .footer__wrapper {
      @apply bg-black bg-opacity-75 h-full;

      .footer__content {
        @apply flex flex-row flex-wrap-reverse sm:flex-nowrap text-white container h-full p-4 justify-center;

        div {
          @apply h-full flex flex-col justify-center content-center items-center sm:mx-4 md:max-w-sm lg:max-w-md;

          h4 {
            @apply text-white font-bold text-2xl my-8;
          }

          div {

            * {
              @apply my-2;
            }

            label {
              @apply flex self-start text-gray-100;
            }

            input {
              @apply bg-transparent border-2 border-gray-100 border-opacity-25 p-4 rounded-lg outline-none;

              &:hover {
                @apply bg-primary bg-opacity-25 border-opacity-50;
              }

              &:focus {
                @apply bg-primary bg-opacity-50 border-opacity-75;
              }

              &.error {
                @apply bg-red-500 bg-opacity-50 border-red-500 border-opacity-50;

                &:hover {
                  @apply bg-red-500 bg-opacity-50 border-red-500 border-opacity-50 appearance-none;
                }

                &:focus {
                  @apply bg-red-500 bg-opacity-50 border-red-500 border-opacity-75;
                }
              }

              &.success {
                @apply bg-green-500 bg-opacity-50 border-green-500 border-opacity-50;

                &:hover {
                  @apply bg-green-500 bg-opacity-50 border-green-500 border-opacity-50 appearance-none;
                }

                &:focus {
                  @apply bg-green-500 bg-opacity-50 border-green-500 border-opacity-75;
                }
              }
            }

            button {
              @apply bg-yellow-600 p-4 rounded-lg my-8 flex justify-center content-center items-center h-16;

              svg {
                @apply mr-2 animate-spin hidden;

                &.loading {
                  @apply flex;
                }
              }

              &:hover {
                @apply bg-yellow-500;
              }
            }
          }
        }

        :last-child {
          @apply sm:max-w-sm md:max-w-sm lg:max-w-md my-auto;

          h4 {
            @apply text-white font-bold text-2xl my-8;
          }

          h5 {
            @apply text-white font-bold text-xl my-4;
          }

          p, li {
            @apply text-gray-200;
          }

          p {
            @apply my-2 text-justify;
          }

          ul {
            @apply list-none;

            li {
              @apply text-center py-2 text-sm font-extrabold;

              address {
                @apply inline;
              }

              a {
                @apply underline;
              }

              svg {
                @apply mr-4;
              }
            }
          }
        }
      }
    }

    .to_top__arrow {
      @apply border-2 border-gray-400 border-opacity-50 cursor-pointer shadow-xl fixed left-8 bottom-8 bg-gray-100 bg-opacity-75 w-12 h-12 rounded-full flex justify-center content-center items-center;

      &:hover {
        @apply bg-opacity-100;
      }

      svg {
        @apply self-center w-full h-full text-primary;
      }
    }

    .alert {
      @apply fixed top-0 sm:top-8 right-0 sm:right-8 bg-white sm:max-w-md shadow-xl p-6 flex flex-col justify-start content-start items-start text-left;

      h4 {
        @apply text-lg text-white font-bold;
      }

      p {
        @apply text-sm text-white;
      }

      button {
        @apply absolute right-4 top-4 w-6 h-6 rounded-full bg-gray-400 bg-opacity-25 p-2 box-content outline-none;

        &:hover {
          @apply bg-opacity-75;

          svg {
            @apply text-white;
          }
        }
      }

      &.error {
        @apply bg-red-500;

        button {
          @apply bg-red-400;

          &:hover {
            @apply bg-red-200;

            svg {
              @apply text-red-600;
            }
          }

          svg {
            @apply text-white;
          }
        }
      }

      &.success {
        @apply bg-green-500;

        button {
          @apply bg-green-400;

          &:hover {
            @apply bg-green-200;

            svg {
              @apply text-green-600;
            }
          }

          svg {
            @apply text-white;
          }
        }
      }
    }
  }
}
