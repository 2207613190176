@layer components {

  //region Navigation
  nav {
    @apply box-content sm:box-border sm:flex h-36 items-center pb-4 sm:pb-0 mx-auto lg:container;

    //region Logo
    .Logo {
      @apply p-4;

      img {
        @apply w-36 sm:w-48 mx-auto sm:ml-6 lg:mx-auto;
      }
    }

    //endregion

    //region Contacts
    .Contacts {
      @apply px-4 pb-4 sm:p-4 justify-start sm:text-right lg:text-center;

      h2 {
        @apply text-sm sm:text-base md:text-xl text-gray-600 mb-1.5;
      }

      a {
        @apply text-base sm:text-lg md:text-2xl text-blue-800 font-extrabold hover:underline;

        .FaIcon {
          @apply mr-3;
        }
      }
    }

    //endregion
  }

  //endregion
}

